<template>
  <b-card no-body>
    <b-card-body>
      <b-card-title>
        <b-row>
          <b-col><div>Setting SNBP</div></b-col>
          <b-col>
            <div align="right">
              <!-- <b-button variant="outline-primary" v-b-modal.modal-sm @click="ModalShow()" class="btn-icon"> <feather-icon icon="PlusIcon" class="mr-25" />Tambah</b-button> -->
            </div>
          </b-col></b-row
        >
      </b-card-title>
      <b-card-text class="blog-content-truncate">
        <b-row>
          <b-col md="6">
            <b-form>
              <b-form-group label="Nama" label-for="nama">
                <b-form-input id="nama" v-model="form.name" placeholder="Nama" />
              </b-form-group>
            </b-form>
          </b-col>
          <b-col md="6">
            <b-form>
              <b-form-group label="Harga" label-for="price">
                <b-form-input
                  id="price"
                  v-model="form.price"
                  @keyup="doFormatRupiah"
                  placeholder="Masukkan harga"
                />
              </b-form-group>
            </b-form>
          </b-col>
          <b-col md="12">
            <b-form>
              <b-form-group label="Deskripsi" label-for="Deskripsi">
                <quill-editor
                  id="Deskripsi"
                  v-model="form.description"
                  placeholder="Masukkan deskripsi"
                />
              </b-form-group>
            </b-form>
          </b-col>

          <b-col md="6">
            <b-form>
              <b-form-group label="Thumbnail" label-for="Thumbnail">
                <b-img
                  :src="'http://file.elp-dev.my.id/' + foto"
                  style="width: 100%"
                  :alt="foto"
                />
              </b-form-group>
            </b-form>
          </b-col>
          <b-col md="6">
            <b-form>
              <b-form-group label="Ganti Thumbnail" label-for="Ganti Thumbnail">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button variant="outline-warning" @click="Upload">
                      <feather-icon icon="UploadCloudIcon" class="mr-25" />
                      Upload
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-file
                    id="Thumbnail"
                    v-model="form.thumbnail"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    no-drop
                    accept="image/*"
                  />
                </b-input-group>
              </b-form-group>
            </b-form>
          </b-col>

          <b-col md="12">
            <template>
              <div class="w-100">
                <p class="float-left mb-0"></p>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="outline-success"
                  class="mr-1 btn-icon"
                  @click.prevent="Tambah"
                >
                  <feather-icon icon="SaveIcon" class="mr-25" />{{ label || "Tambah" }}
                </b-button>
                <!-- <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-warning"
                  class="float-right btn-icon"
                  @click="ModalUploadThumbnail = true"
                >
                  <feather-icon icon="UploadCloudIcon" class="mr-25" />Tutup
                </b-button> -->
              </div>
            </template>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card-body>
    <b-modal
      v-model="ModalUploadThumbnail"
      id="modal-upload"
      centered
      size="lg"
      title="Upload Thumbnail"
    >
      <b-card-text>
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group label="Thumbnail" label-for="Thumbnail">
                <b-form-file
                  id="Thumbnail"
                  v-model="form.thumbnail"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  no-drop
                  accept="image/*"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-card-text>

      <template #modal-footer>
        <div class="w-100">
          <p class="float-left mb-0"></p>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="outline-success"
            class="mr-1 btn-icon"
            @click.prevent="UploadThumbnail"
          >
            <feather-icon icon="SaveIcon" class="mr-25" />{{ label || "Tambah" }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="float-right btn-icon"
            @click.prevent="tutupModal"
          >
            <feather-icon icon="LogOutIcon" class="mr-25" />Tutup
          </b-button>
        </div>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import {
  BImg,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormFile,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BFormFile,
    BImg,
    quillEditor,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      id: null,
      activeAction: null,
      label: "Simpan",

      ModalUploadThumbnail: false,
      //Data
      data: [],
      form: {},
      foto: null,
      //Modal
      titleModal: null,
      Modal: false,
      ModalEdit: false,
      //Table
      fields: [
        { key: "index", label: "No" },
        { key: "name", label: "Nama", sortable: true },
        { key: "school_origin", label: "Asal Sekolah ", sortable: true },
        { key: "school_accreditation", label: "Akreditasi Sekolah", sortable: true },
        { key: "created_at", label: "Tanggal Daftar", sortable: true },
        { key: "detail", label: "" },
      ],
      selected: [],
      total: [],
      record: [],
      optFilter: [
        { id: "name", value: "Nama" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // filter: "Kategori",
      search: null,
      idd: null,
    };
  },
  watch: {},
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    // ModalUpload() {
    //   this.id = form.id;
    //   this.form = form;
    //   this.activeAction = "upload";
    //   this.titleModal = "Upload Thumbnail";
    //   this.ModalUploadThumbnail = true;
    // },
    tutupModal() {
      this.ModalModalUploadThumbnail = false;
      this.getData();
    },
    doFormatRupiah() {
      this.form.price = this.formatRupiah(this.form.price);
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    Tambah() {
      if (this.form.name == null || this.form.name == "") {
        this.pesanGagal();
        return false;
      }
      const fd = new FormData();
      // fd.append("thumbnail", this.form.thumbnail == null ? 0 : this.form.thumbnail);
      fd.append("name", this.form.name == null ? 0 : this.form.name);
      fd.append(
        "price",
        this.unFormatRupiah(this.form.price) == null
          ? 0
          : this.unFormatRupiah(this.form.price)
      );
      fd.append("description", this.form.description == null ? 0 : this.form.description);
      if (this.id) {
        fd.append("id", this.form.id);
      }

      this.$store
        .dispatch("snbp/createUpdateDeleteSetting", fd)
        .then(() => {
          this.getData();
          this.displaySuccess({
            text: "Setting SNBP berhasil disimpan",
          });
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    Upload() {
      if (this.form.thumbnail == null || this.form.thumbnail == "") {
        this.pesanGagal();
        return false;
      }
      const fd = new FormData();
      fd.append("thumbnail", this.form.thumbnail == null ? 0 : this.form.thumbnail);
      fd.append("name", this.form.name == null ? 0 : this.form.name);
      fd.append(
        "price",
        this.unFormatRupiah(this.form.price) == null
          ? 0
          : this.unFormatRupiah(this.form.price)
      );
      fd.append("description", this.form.description == null ? 0 : this.form.description);
      if (this.id) {
        fd.append("id", this.form.id);
      }

      this.$store
        .dispatch("snbp/createUpdateDeleteSetting", fd)
        .then(() => {
          this.getData();
          this.displaySuccess({
            text: "Upload thumbnail SNBP berhasil disimpan",
          });
          this.form.thumbnail = null;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getData() {
      const params = {};
      this.$store
        .dispatch("snbp/indexSetting", params)
        .then((response) => {
          let data = response.data;
          this.form = data;
          this.totalRows = this.form.length;
          this.foto = this.form.thumbnail;
          this.doFormatRupiah();
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        })
        .finally(() => {
          this.selected = [];
        });
    },
    // detail(data) {
    //   this.$store.commit("snbp/SET_DETAIL", data.item);
    //   this.$router.push({ name: "admin-snbp-detail" });
    // },
  },
  created() {},
  async mounted() {
    this.getData();
    this.doFormatRupiah();
  },
};
</script>

<style></style>
