var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', [_c('b-card-title', [_c('b-row', [_c('b-col', [_c('div', [_vm._v("Setting SNBP")])]), _c('b-col', [_c('div', {
    attrs: {
      "align": "right"
    }
  })])], 1)], 1), _c('b-card-text', {
    staticClass: "blog-content-truncate"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form', [_c('b-form-group', {
    attrs: {
      "label": "Nama",
      "label-for": "nama"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "nama",
      "placeholder": "Nama"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form', [_c('b-form-group', {
    attrs: {
      "label": "Harga",
      "label-for": "price"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "price",
      "placeholder": "Masukkan harga"
    },
    on: {
      "keyup": _vm.doFormatRupiah
    },
    model: {
      value: _vm.form.price,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "price", $$v);
      },
      expression: "form.price"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form', [_c('b-form-group', {
    attrs: {
      "label": "Deskripsi",
      "label-for": "Deskripsi"
    }
  }, [_c('quill-editor', {
    attrs: {
      "id": "Deskripsi",
      "placeholder": "Masukkan deskripsi"
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form', [_c('b-form-group', {
    attrs: {
      "label": "Thumbnail",
      "label-for": "Thumbnail"
    }
  }, [_c('b-img', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "src": 'http://file.elp-dev.my.id/' + _vm.foto,
      "alt": _vm.foto
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form', [_c('b-form-group', {
    attrs: {
      "label": "Ganti Thumbnail",
      "label-for": "Ganti Thumbnail"
    }
  }, [_c('b-input-group', [_c('b-input-group-prepend', [_c('b-button', {
    attrs: {
      "variant": "outline-warning"
    },
    on: {
      "click": _vm.Upload
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "UploadCloudIcon"
    }
  }), _vm._v(" Upload ")], 1)], 1), _c('b-form-file', {
    attrs: {
      "id": "Thumbnail",
      "placeholder": "Choose a file or drop it here...",
      "drop-placeholder": "Drop file here...",
      "no-drop": "",
      "accept": "image/*"
    },
    model: {
      value: _vm.form.thumbnail,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "thumbnail", $$v);
      },
      expression: "form.thumbnail"
    }
  })], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [[_c('div', {
    staticClass: "w-100"
  }, [_c('p', {
    staticClass: "float-left mb-0"
  }), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mr-1 btn-icon",
    attrs: {
      "type": "submit",
      "variant": "outline-success"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.Tambah($event);
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "SaveIcon"
    }
  }), _vm._v(_vm._s(_vm.label || "Tambah") + " ")], 1)], 1)]], 2)], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-upload",
      "centered": "",
      "size": "lg",
      "title": "Upload Thumbnail"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100"
        }, [_c('p', {
          staticClass: "float-left mb-0"
        }), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "mr-1 btn-icon",
          attrs: {
            "type": "submit",
            "variant": "outline-success"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.UploadThumbnail($event);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "SaveIcon"
          }
        }), _vm._v(_vm._s(_vm.label || "Tambah") + " ")], 1), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "float-right btn-icon",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.tutupModal($event);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "LogOutIcon"
          }
        }), _vm._v("Tutup ")], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.ModalUploadThumbnail,
      callback: function callback($$v) {
        _vm.ModalUploadThumbnail = $$v;
      },
      expression: "ModalUploadThumbnail"
    }
  }, [_c('b-card-text', [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Thumbnail",
      "label-for": "Thumbnail"
    }
  }, [_c('b-form-file', {
    attrs: {
      "id": "Thumbnail",
      "placeholder": "Choose a file or drop it here...",
      "drop-placeholder": "Drop file here...",
      "no-drop": "",
      "accept": "image/*"
    },
    model: {
      value: _vm.form.thumbnail,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "thumbnail", $$v);
      },
      expression: "form.thumbnail"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }